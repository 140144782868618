import React, { Suspense, lazy, useCallback, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import i18n from 'i18nconfig';
import PrivateRoute from 'PrivateRoute';

import Spinner from 'components/Spinner';
import { closeSnackbar } from 'store/features/app/appSlice';
import { useAppDispatch, useAppSelector } from 'store/reduxHooks';
import { getCountry } from 'utils/helpers';
import { useGuestUserInitialization } from 'utils/hooks';

// Lazy load components
const Homepage = lazy(() => import('./pages/Homepage'));
const Authpage = lazy(() => import('./pages/Profile/Authpage'));
const RegisterComponent = lazy(() => import('./components/Auth/Register'));
const LoginComponent = lazy(() => import('./components/Auth/Login'));
const ConfirmPaymentPage = lazy(() => import('./pages/Checkout/ConfirmPaymentPage'));
const InstantOrder = lazy(() => import('./pages/Order'));
const ProfilePage = lazy(() => import('./pages/Profile/Profilepage'));
const Orderspage = lazy(() => import('./pages/Teams/Orderspage'));
const OrderDetails = lazy(() => import('./components/Table/OrderDetails'));
const AddTeampage = lazy(() => import('./pages/Profile/AddTeampage'));
const Teampage = lazy(() => import('./pages/Teams/Teampage'));
const Bilingpage = lazy(() => import('./pages/Teams/Billingpage'));
const Page404 = lazy(() => import('./pages/Page404'));
const FileStatusListener = lazy(() => import('./components/PrintFileLIstener'));
const BusinessPage = lazy(() => import('./pages/BusinessPage'));
const RymanPage = lazy(() => import('./pages/RymanPage'));
const MailboxexPage = lazy(() => import('./pages/MailboxexPage'));
const StudentsPage = lazy(() => import('./pages/StudentsPage'));
const PrintSnackBar = lazy(() => import('./components/PrintSnackBar'));
const BookbindingPage = lazy(() => import('./pages/BookbindingPage'));
const ColorPrintingPage = lazy(() => import('./pages/ColorPrintingpage'));
const PrinttLocationsPage = lazy(() => import('./pages/PrinttLocations'));
const PrintingGuide = lazy(() => import('./pages/PrintingGuide'));
const GuidePage = lazy(() => import('./pages/PrintingGuide/GuidePage'));
const BookPrintingPage = lazy(() => import('./pages/BookPrintingPage'));
type RouteTypes = {
  path: string;
  element: React.ReactElement;
  isPrivate?: boolean;
};

const routes: RouteTypes[] = [
  { path: '/', element: <Homepage /> },
  { path: '/auth', element: <Authpage /> },
  { path: '/register', element: <RegisterComponent /> },
  { path: '/login', element: <LoginComponent /> },
  { path: '/congratulations', element: <ConfirmPaymentPage /> },
  { path: '/instantOrder', element: <InstantOrder /> },
  { path: '/profile', element: <ProfilePage />, isPrivate: true },
  { path: '/orders', element: <Orderspage />, isPrivate: true },
  { path: '/orders/:id', element: <OrderDetails />, isPrivate: true },
  { path: '/addteam', element: <AddTeampage />, isPrivate: true },
  { path: '/team', element: <Teampage />, isPrivate: true },
  { path: '/billing', element: <Bilingpage />, isPrivate: true },
  { path: '/business', element: <BusinessPage /> },
  { path: '/ryman', element: <RymanPage /> },
  { path: '/mailboxes', element: <MailboxexPage /> },
  { path: '/students', element: <StudentsPage /> },
  { path: '/bookbinding', element: <BookbindingPage /> },
  { path: '/colorPrinting', element: <ColorPrintingPage /> },
  { path: '/locations', element: <PrinttLocationsPage /> },
  { path: '/locations/:location', element: <PrinttLocationsPage /> },
  { path: '/printingGuides', element: <PrintingGuide /> },
  { path: '/printingGuides/:guide', element: <GuidePage /> },
  { path: '/bookPrinting', element: <BookPrintingPage /> },
  { path: '*', element: <Page404 /> },
];

const Router = () => {
  const { snackbar } = useAppSelector((state) => state.app);
  const { userType } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const isInitialized = useGuestUserInitialization(userType);

  const getBrowserLanguage = useCallback(async () => {
    const country = await getCountry();
    if (country === 'BG') {
      i18n.changeLanguage('bg');
    }
  }, []);

  useEffect(() => {
    getBrowserLanguage();
  }, [getBrowserLanguage]);

  const handleClose = useCallback(() => {
    dispatch(closeSnackbar());
  }, [dispatch]);

  if (!isInitialized) {
    return <Spinner />;
  }

  return (
    <BrowserRouter>
      <HashRouterHandler />
      <LocaleRouter>
        <Suspense fallback={<Spinner />}>
          <FileStatusListener />
          <PrintSnackBar
            open={snackbar.open}
            messages={[snackbar.message]}
            handleClose={handleClose}
            type={snackbar.type}
          />
          <Routes>
            <Route path='/' element={<DefaultLocaleRedirect />} />
            <Route path='/:locale/*' element={<LocalizedRoutes routes={routes} />} />
            <Route path='*' element={<DefaultLocaleRedirect />} />
          </Routes>
        </Suspense>
      </LocaleRouter>
    </BrowserRouter>
  );
};

const LocaleRouter: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const locale = pathSegments[1];

    // If we're at the root or the first segment isn't a valid locale
    if (!locale || (locale !== 'en' && locale !== 'bg')) {
      const defaultLocale = i18n.language || 'en';
      const newPath = `/${defaultLocale}${location.pathname === '/' ? '' : location.pathname}`;
      navigate(newPath, { replace: true });
    } else {
      i18n.changeLanguage(locale);
    }
  }, [location, navigate]);

  return <>{children}</>;
};

const LocalizedRoutes: React.FC<{ routes: RouteTypes[] }> = ({ routes }) => {
  return (
    <Routes>
      {routes.map(({ path, element, isPrivate }) => (
        <Route
          key={path}
          path={path === '/' ? '/' : `${path}`}
          element={isPrivate ? <PrivateRoute element={element} isPrivate={isPrivate} /> : element}
        />
      ))}
      <Route path='*' element={<Page404 />} />
    </Routes>
  );
};

const DefaultLocaleRedirect: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const defaultLocale = i18n.language || 'en';
    const newPath = `/${defaultLocale}${location.pathname === '/' ? '' : location.pathname}`;
    navigate(newPath, { replace: true });
  }, [navigate, location]);

  return <Spinner />;
};

const HashRouterHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.hash.startsWith('#!/')) {
      const newPath = location.hash.replace('#!/', '');
      navigate(newPath, { replace: true });
    }
  }, [location, navigate]);

  return null;
};

export default Router;
